@mixin ui-slider() {
	.common-ui-slider {
		padding: 19px 0 19px 12px;

		.p-slider {
			width: 159px;
			height: 2px;
		}

		.p-slider-range {
			background: $stamps-ui-slider-range-color;
		}

		.p-slider-handle {
			height: 15px;
			width: 15px;
			border: 2px solid $stamps-ui-slider-handle-color;

			&:active {
				cursor: grabbing;
			}
		}
	}
}
