@mixin ui-accordion() {
	.common-ui-accordion {
		.p-accordion-header,
		.p-accordion-header:not(.p-disabled).p-highlight,
		.p-accordion-header:not(.p-disabled).p-highlight:hover,
		.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
			a {
				display: flex;
				text-decoration: none;
				justify-content: space-between;
				align-items: center;
				background-color: transparent;
				border-radius: 0;
				padding: $stamps-ui-accordion-header-padding;

				.p-accordion-header-text {
					order: 1;
					color: $stamps-ui-accordion-header-text-color;
				}

				.p-accordion-toggle-icon {
					order: 2;
					color: $stamps-ui-accordion-toggle-icon-color;
					font-size: 10px;
				}
			}
		}

		.p-accordion-header:not(.p-disabled).p-highlight:hover,
		.p-accordion-header:not(.p-highlight):not(.p-disabled):hover {
			a {
				.p-accordion-header-text,
				.p-accordion-toggle-icon {
					color: $stamps-ui-accordion-header-hover-text-color;
				}
			}
		}

		.p-accordion-tab {
			margin-bottom: 0;
			border-top: 1px solid $stamps-ui-accordion-tab-border-color;
			border-bottom: none;
		}
	}
}
