@import 'authentication.scss';
@import 'billing-status.scss';
@import 'branded-tracking.scss';
@import 'carrier.scss';
@import 'cleanse-address.scss';
@import 'cost-codes.scss';
@import 'emails.scss';
@import 'feedback.scss';
@import 'global.scss';
@import 'grid.scss';
@import 'header.scss';
@import 'inbounds-details.scss';
@import 'layout.scss';
@import 'learning-center.scss';
@import 'marketing.scss';
@import 'marketplaces.scss';
@import 'menu.scss';
@import 'modal.scss';
@import 'payment.scss';
@import 'account-verification.scss';
@import 'print.scss';
@import 'rate-advisor.scss';
@import 'reset.scss';
@import 'settings.scss';
@import 'subscription-plan.scss';
@import 'tags.scss';
@import 'terms.scss';
@import 'ui.scss';
@import 'upgrade-msg.scss';
@import 'upgrade-plan-comparison.scss';

@mixin base-theme() {
	@include reset();

	body {
		@include authentication();
		@include billing-status();
		@include branded-tracking();
		@include carrier();
		@include cleanse-address();
		@include cost-codes();
		@include emails();
		@include feedback();
		@include global();
		@include grid();
		@include header();
		@include inbounds-details();
		@include layout();
		@include marketing();
		@include marketplaces();
		@include menu();
		@include modal();
		@include payment();
		@include account-verification();
		@include print();
		@include rate-advisor();
		@include settings();
		@include subscription-plan();
		@include tags();
		@include terms();
		@include ui();
		@include learning-center();
		@include upgrade-msg();
		@include upgrade-plan-comparison();
	}
}
